export const USER_AUTH_EVENT = 'tempu-auth-login';
export const USER_AUTH_KEY = 'tempu-auth-user';

export interface AuthTokens{
	access: string,
	refresh: string,
	expires: number,
	refresh_expires: number,
}

export interface UserLogin{
	user: {
		user_id: string,
		email: string,
		first_name: string,
		last_name: string,
		zipcode: string,
		onboarded: boolean,
		phone: string,
	},
	tokens: AuthTokens
}

export interface CreateForm{
	first_name: string,
	last_name: string,
	email: string,
	phone: string,
	password: string,
	password_repeat: string,
}