import React, { useRef, useState } from 'react';
import { Button, Header, Input, Message, Checkbox, Groupper, Field } from 'react-frontier'
import { APPLE_CLIENT, CAPTCHA_ACTIVE, CDN_ROOT, RECAPTCHA_KEY } from './AuthAPI';
import { useAuth } from './AuthHooks';
import { AuthTokens, CreateForm } from './AuthClasses';
import { useValidator } from '@tempu/components/Hooks'
import { bindClick } from '@tempu/components/Util';
import AppleSignIn from 'react-apple-signin-auth'
import ReCAPTCHA from 'react-google-recaptcha';
import API from './AuthAPI';

import 'frontier-css/css/frontier.css';
import './login.scss';

enum LoginMode{
	LOGIN = 1,
	FORGOT = 2,
	TOTP = 3,
	CREATE = 4,
}

interface LoginForm{
	email: string,
	password: string,
	totp: string,
	remember: boolean,
}

function App() {
	var { user, user_saved, clearSaved, loginSaved } = useAuth();
	var recaptchaRef = useRef<ReCAPTCHA>(null);
	var [mode, setMode] = useState<LoginMode>(LoginMode.LOGIN);
	var [sending, setSending] = useState<boolean>(false);
	var [loggedIn, setLoggedIn] = useState<boolean>(false);
	var [loginError, setLoginError] = useState<string>(null);
	var [forgotSent, setForgotSent] = useState<boolean>(false);
	var [createdUser, setCreatedUser] = useState<boolean>(false);
	var loginForm = useValidator<LoginForm>({
		email: '',
		password: '',
		totp: '',
		remember: false,
	}, {
		email: [{
			rule: 'email',
			params: [5],
			prompt: `El correo electrónico no es válido`
		}],
	});
	var createForm = useValidator<CreateForm>({
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		password: '',
		password_repeat: '',
	}, {
		first_name: [{ rule: 'length', params: [3, 32], label: 'Nombre' }],
		last_name: [{ rule: 'length', params: [3, 64], label: 'Apellido' }],
		email: [{ rule: 'email', label: 'Correo electrónico' }],
		phone: [{ rule: 'phone', label: 'Teléfono' }],
		password: [{ rule: 'length', params: [7, 128], label: 'Contraseña' }],
		password_repeat: [{ rule: 'match', params: ['password'], prompt: 'Las contraseñas no concuerdan.' }],
	})
	
	var query = new URLSearchParams(window.location.search);
	var continue_url = decodeURIComponent(query.get('continue') || '');
	var params_valid = !!continue_url && continue_url.length>=10;

	var getContinueUrl = (tokens: AuthTokens)=>{
		if(!continue_url || !tokens) return null;
		continue_url = continue_url.replace(/\/$/gi, '');
		var params : any = {
			at: tokens.access,
			e: tokens.expires,
		}
		var redirect_url = query.get('redirect');
		if(redirect_url && redirect_url.length>=10){
			params.r = redirect_url;
		}
		return `${continue_url}?${Object.keys(params).map(a=>`${a}=${params[a]}`).join('&')}`
	}

	var refreshUser = ()=>{
		if(!user_saved) return;
		setSending(true);
		loginSaved().then(res=>{
			if(res.error) return setLoginError(res.message);
			if(!res.data.tokens?.access){
				return setLoginError(`Hubo un error inesperado iniciando sesión (LCL-SVD-2)`);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setLoginError(`Hubo un error inesperado haciendo login. (LCL-SVD-1)`);
		}).finally(()=>{
			setSending(false);
		})
	}

	var submitLogin = (captcha: string)=>{
		setSending(true);
		setCreatedUser(false);
		var { data, setPrompts } = loginForm;
		API.login(data.email, data.password, captcha, data.remember).then(res=>{
			if(res.error) return setPrompts([res.message]);
			if(!res.data.tokens?.access){
				return setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ATHLG-2)']);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ATHLG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitCreate = (captcha: string)=>{
		setSending(true);
		var { data, setPrompts } = createForm;
		API.createUser(data, captcha).then(res=>{
			if(res.error) return setPrompts([res.message]);
			createForm.reset();
			loginForm.setData('email', res.data.email);
			setMode(LoginMode.LOGIN);
			setCreatedUser(true);
		}).catch(err=>{
			setPrompts(['Hubo un error creando el usuario (LCL-CRTUS-1)']);
		}).finally(()=>{
			setSending(false);
		})
	}

	var getCaptcha = async (setPrompts?: typeof loginForm.setPrompts)=>{
		var cph : string = null;
		if(CAPTCHA_ACTIVE){
			try{
				setSending(true);
				recaptchaRef.current.reset();
				var cph = await recaptchaRef.current.executeAsync()
				if(!cph){
					if(!setPrompts) setPrompts(['Hubo un error realizando el captcha (LCL-ATHSB-1)']);
					return null;
				}
			}catch(e){
				if(setPrompts) setPrompts(['Hubo un error realizando el captcha (LCL-ATHSB-2)']);
				return null;
			}finally{
				setSending(false);
			}
		}else{
			cph = 'NOT ACTIVE';
		}

		return cph;
	}

	var login = async ()=>{
		var { validate, setPrompts } = loginForm;
		
		var { valid } = validate(null, {
			password: mode===LoginMode.LOGIN ? [{
				rule: 'minLength', params: [5], prompt: 'La contraseña debe de ser de mínimo 5 caracteres'
			}] : []
		});
		if(!valid) return;

		var captcha = await getCaptcha(setPrompts);
		if(!captcha) return;
		return submitLogin(captcha);
	}

	var create = async ()=>{
		var { validate, setPrompts } = createForm;
		var { valid } = validate();
		if(!valid) return;

		var captcha = await getCaptcha(setPrompts);
		if(!captcha) return;

		return submitCreate(captcha);
	}

	var onAppleSuccess = (data: any)=>{
		console.log(JSON.stringify(data));
	}

	var onAppleError = ()=>{

	}

	var logged_in = loggedIn && !!user;
	var login_saved = !!user && user_saved;

	return <div className="tp login-screen">
		<img className='tp logo' src={`${CDN_ROOT}/logo/LogoRH_128.webp`} alt="Ebentu" />
		<Groupper defaultStyle={false} className='main'>
			{logged_in ? (
				<div className="contents full">
					<Header
						iconName='check-circle' 
						iconStyle={{ color: '#1a9461', marginBottom: 10 }} 
						text={`¡Hola, ${(user?.user.first_name || 'Usuario')}!`} 
						subtext='Te redireccionaremos pronto a tu destino' 
						subheaderStyle={{ marginTop: 5 }}
						actions={(
							<Button text='Continuar' size='tiny' style={{ padding: '2px 10px', minWidth: 100 }} basic color='green' as={'a'} href={getContinueUrl(user?.tokens)} />
						)}
					/>
				</div>
			) : params_valid ? <>
				<div className="head">
					<Header centered text={mode===LoginMode.CREATE ? 'Crear cuenta' : 'Iniciar sesión'} subtext={mode===LoginMode.FORGOT ? 'Olvidé mi contraseña' : null} />
				</div>
				{(login_saved && user) ? (
					<div className="contents full semi">
						{loginError ? <>
							<Header iconName='exclamation-triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Error' subtext={loginError} />
							<Button text='Regresar' style={{ marginTop: 15, minWidth: 200 }} onClick={()=>setLoginError(null)} loading={false} />
						</> : <>
							<Header
								iconName='user-circle' 
								text={`${user.user.first_name} ${user.user.last_name}`} 
								subtext='Guardamos tu sesión'
							/>
							<Button text='Iniciar sesión' style={{ marginTop: 15, minWidth: 200 }} color='red' onClick={refreshUser} loading={sending} />
							<Button text='Cerrar sesión' size='tiny' style={{ marginTop: 5, minWidth: 120 }} basic color='black' onClick={clearSaved} disabled={sending} />
						</>}
					</div>
				)  : forgotSent ? (
					<div className="contents semi full">
						<Header 
							iconName='envelope' 
							iconStyle={{ fontSize: 70, marginBottom: 10 }} 
							text='Enlace enviado' 
							subtext={`Si existe una cuenta de Ebentu con este correo electrónico, te enviamos un correo con instrucciones para restablecer tu contraseña.`} 
							subheaderStyle={{ lineHeight: '16px' }} 
						/>
						<Button text='Iniciar sesión' color='black' style={{ marginTop: 20, minWidth: 200 }} onClick={()=>{
							setForgotSent(null);
							setMode(LoginMode.LOGIN);
						}} loading={false} />
					</div>
				) : (
					mode===LoginMode.LOGIN ? <>
						{!!createdUser && (
							<Message style={{ marginBottom: 15 }} centered type='success'>
								¡Se ha creado tu usuario correctamente! Ahora podrás iniciar sesión con tu nuevo usuario
							</Message>
						)}
						<Input label={'Correo electrónico'} error={loginForm.errors.email} value={loginForm.data.email} onChange={loginForm.onDataChange('email')} submitOnEnter onSubmit={login} />
						<Input label='Contraseña' style={{ marginBottom: 0 }} error={loginForm.errors.password} value={loginForm.data.password} onChange={loginForm.onDataChange('password')} inputType='password' submitOnEnter onSubmit={login} />
						<Checkbox color='red' label={'Recordar mi sesión'} style={{ marginTop: 15 }} checked={loginForm.data.remember} onChange={loginForm.onDataChange('remember')} />
						
						<Message type='error' list={loginForm.prompts} style={{ marginTop: 15, marginBottom: -5 }} />
						<Button color='red' text='Iniciar sesión' fluid style={{ marginTop: 20 }} loading={sending} onClick={login} />

						<Groupper.Divider type='line text' text={'Otras opciones'} />
						<Button text='Crear cuenta' fluid iconName={'plus'} onClick={bindClick(setMode, LoginMode.CREATE)} />
						{/* <Button text='Google' fluid color='blue' iconName={'google'} style={{ marginTop: 5 }} /> */}
						{/* <AppleSignIn
							authOptions={{
								clientId: APPLE_CLIENT,
								scope: 'email name',
								redirectURI: `${window.location.origin}/apple`,
								state: 'test-state',
								nonce: 'nonce',
								usePopup: true,
							}}
							className='fr apple-button'
							uiType='dark'
							onSuccess={onAppleSuccess}
							onError={onAppleError}
						/> */}
						{/* <Button text='Apple' fluid color='black' iconName={'fa-brands fa-apple'} style={{ marginTop: 5 }} /> */}

						<Groupper.Divider />
						<div className="create">
							¿No tienes una cuenta?
							<Button text='Crear cuenta' size='tiny' basic color='black' onClick={bindClick(setMode, LoginMode.CREATE)} />
						</div>
						<div className="create">
							<Button text='¿Olvidaste tu contraseña?' size='tiny' basic color='black' onClick={bindClick(setMode, LoginMode.FORGOT)} />
						</div>
					</> : mode===LoginMode.FORGOT ? <>
						<Message centered type='info' style={{ marginBottom: 15 }}>
							Ingresa el correo electrónico de tu cuenta de <b>Ebentu</b> para recuperar tu cuenta
						</Message>
						{/* <Input label={'Correo electrónico'} error={errors.email} value={data.email} onChange={onDataChange('email')} /> */}
						<Button color='black' text='Recuperar contraseña' fluid style={{ marginTop: 20 }} />
					</> : mode===LoginMode.CREATE ? <>
						<Message centered style={{ marginBottom: 10 }}>
							Crea tu cuenta de <b>Ebentu</b> para postularte a empleos dentro de la plataforma
						</Message>
						<Input label='Nombre' error={createForm.errors.first_name} value={createForm.data.first_name} onChange={createForm.onDataChange('first_name')} />
						<Input label='Apellidos' error={createForm.errors.last_name} value={createForm.data.last_name} onChange={createForm.onDataChange('last_name')} />

						<Groupper.Divider type='dashed' />
						<Input label='Correo electrónico' error={createForm.errors.email} value={createForm.data.email} onChange={createForm.onDataChange('email')} inputType='email' />
						<Input label='Teléfono' error={createForm.errors.phone} value={createForm.data.phone} onChange={createForm.onDataChange('phone')} inputType='tel' />

						<Groupper.Divider type='dashed' />
						<Input label='Contraseña' error={createForm.errors.password} value={createForm.data.password} onChange={createForm.onDataChange('password')} inputType='password' />
						<Input label='Repetir contraseña' error={createForm.errors.password_repeat || createForm.errors.password} value={createForm.data.password_repeat} inputType='password' onChange={createForm.onDataChange('password_repeat', false, false, false)} />
						<Message type='error' list={createForm.prompts} style={{ marginTop: 15, marginBottom: -10 }} />

						<Button color='red' text='Crear cuenta' fluid style={{ marginTop: 30 }} loading={sending} onClick={create} />
					</> : null
				)}
				{mode!==LoginMode.LOGIN && <>
					<Groupper.Divider />
					<div className="create">
						<Button basic color='black' disabled={sending} iconName='arrow-left' text='Regresar' size='tiny' style={{ width: 200, display: 'block' }} onClick={bindClick(setMode, LoginMode.LOGIN)} />
					</div>
				</>}
			</> : (
				<div className="contents full">
					<Header iconName='exclamation-triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Autenticación inválida' subtext='El desarrollo ha configurado mal la autenticación de usuario' />
				</div>
			)}
			{CAPTCHA_ACTIVE && (
				<ReCAPTCHA
					hl='es-419'
					size='invisible'
					sitekey={RECAPTCHA_KEY}
					ref={recaptchaRef}
				/>
			)}
		</Groupper>
	</div>
}

export default App;